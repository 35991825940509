<template>
  <div>
    <div class="vs-con-input-label w-full">
      <vs-input
        :disabled="disabled"
        type="text"
        class="w-full border-grey-light"
        :value="colors"
        @input="handleTextUpdate"
        :danger="required? !validHex : false"
        danger-text="Invalid Hex code"
        :icon-no-border="false"
      />
      <div
        v-if="validHex"
        class="current-color border w-6 h-6 rounded-md"
        :style="{ 'background-color': previewColor }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    "color" : String,
    "required" : Boolean,
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    update(event) {
      this.$emit("input", event.target.value);
    },
    handleTextUpdate(value) {
      this.$emit("input", value);
    },
  },
  data() {
    return {
      colors:''
    };
  },
  computed: {
    previewColor() {
      return "#" + this.color.substring(3, 9) + this.color.substring(1, 3);
    },
    validHex() {
      return /^#[A-Fa-f0-9]{8}$/.test(this.color);
    },
  },
  updated() {
    this.colors = this.color;
  },
  created() {
    this.colors = this.color;
  },
};
</script>

<style scoped>
.current-color {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 9px;
  top: 9px;
  z-index: 3;
}
</style>

<style>
.input-icon-validate.vs-input--icon-validate {
  display: none !important;
}
</style>