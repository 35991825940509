var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "vs-con-input-label w-full" },
      [
        _c("vs-input", {
          staticClass: "w-full border-grey-light",
          attrs: {
            disabled: _vm.disabled,
            type: "text",
            value: _vm.colors,
            danger: _vm.required ? !_vm.validHex : false,
            "danger-text": "Invalid Hex code",
            "icon-no-border": false
          },
          on: { input: _vm.handleTextUpdate }
        }),
        _vm.validHex
          ? _c("div", {
              staticClass: "current-color border w-6 h-6 rounded-md",
              style: { "background-color": _vm.previewColor }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }