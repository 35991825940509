var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      {
        staticClass:
          "vx-row placeholder-opacity-100 placeholder-black::placeholder"
      },
      [
        _vm._l(
          _vm.value.template.filter(function(field) {
            return field.input !== "localized"
          }),
          function(mod) {
            return _c(
              "div",
              {
                key: mod.key,
                class:
                  "vx-col w-full md:w-1/" +
                  (_vm.columns === undefined ? 3 : _vm.columns)
              },
              [
                _c(
                  "label-form",
                  { attrs: { info: _vm.itemName(mod.subtitle) } },
                  [_vm._v(_vm._s(_vm.itemName(mod.title, mod.required)))]
                ),
                mod.input === "textfield"
                  ? _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        disabled: _vm.isReadOnly,
                        value: _vm.hasValue(_vm.value.values[mod.key])
                          ? _vm.value.values[mod.key]
                          : "",
                        danger: _vm.checkForError(
                          _vm.value.values[mod.key],
                          mod.required
                        ),
                        "danger-text": "Invalid answer"
                      },
                      on: {
                        input: function($event) {
                          return _vm.update($event, mod.key, mod.input)
                        }
                      }
                    })
                  : _vm._e(),
                mod.input === "select"
                  ? _c(
                      "vs-select",
                      {
                        staticClass: "w-full",
                        class: [
                          !_vm.value.values[mod.key] && mod.required === true
                            ? "border-red"
                            : "border-red"
                        ],
                        attrs: {
                          disabled: _vm.isReadOnly,
                          value: _vm.hasValue(_vm.value.values[mod.key])
                            ? _vm.value.values[mod.key]
                            : "",
                          danger: _vm.checkForError(
                            _vm.value.values[mod.key],
                            mod.required
                          ),
                          "danger-text": "Invalid answer"
                        },
                        on: {
                          input: function($event) {
                            return _vm.update($event, mod.key, mod.input)
                          }
                        }
                      },
                      _vm._l(mod.values, function(item) {
                        return _c("vs-select-item", {
                          key: item,
                          attrs: { value: item, text: item }
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                mod.input === "checkbox"
                  ? _c(
                      "div",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            attrs: {
                              disabled: _vm.isReadOnly,
                              value: _vm.hasValue(_vm.value.values[mod.key])
                                ? _vm.value.values[mod.key]
                                : ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.update($event, mod.key, mod.input)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.itemName(mod.subtitle)) + " "
                            )
                          ]
                        ),
                        _vm.checkForError(
                          _vm.value.values[mod.key],
                          mod.required
                        )
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "con-text-validation span-text-validation-danger"
                              },
                              [_vm._v("Required")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                mod.input === "color"
                  ? _c("input-color", {
                      attrs: {
                        disabled: _vm.isReadOnly,
                        color:
                          _vm.value.values[mod.key] == undefined
                            ? "#00000000"
                            : _vm.value.values[mod.key],
                        required: mod.required
                      },
                      on: {
                        input: function($event) {
                          return _vm.update($event, mod.key, mod.input)
                        }
                      }
                    })
                  : _vm._e(),
                mod.input === "number"
                  ? _c("vs-input", {
                      staticClass: "w-full border-grey-light",
                      attrs: {
                        disabled: _vm.isReadOnly,
                        type: "number",
                        min: "0",
                        value: _vm.hasValue(_vm.value.values[mod.key])
                          ? _vm.value.values[mod.key]
                          : "",
                        danger: _vm.checkForError(
                          _vm.value.values[mod.key],
                          mod.required
                        ),
                        "danger-text": "Invalid answer"
                      },
                      on: {
                        input: function($event) {
                          return _vm.update($event, mod.key, mod.input)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }
        ),
        _vm.localizedFields.template.length > 0
          ? _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("LocalizedContent", {
                  attrs: {
                    "fields-definition": _vm.localizedFields.template,
                    languages: _vm.languages,
                    "fixed-languages": true,
                    value: _vm.localizedFields.values,
                    columns: _vm.columns || 2
                  },
                  on: { input: _vm.handleLocalizedInput }
                })
              ],
              1
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }